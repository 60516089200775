import type { AppProps } from "next/app";

import { NextUIProvider } from "@nextui-org/react";
import { ThemeProvider as NextThemesProvider } from "next-themes";
import { useRouter } from "next/router";

import React from 'react'; // Import React

// import { fontSans, fontMono } from "../config/fonts";

import { ContextProvider } from './contexts/ContextProvider';
require('@solana/wallet-adapter-react-ui/styles.css');

import "./styles/globals.css";

export default function App({ Component, pageProps }: AppProps) {
  const router = useRouter();
  return (
      <ContextProvider>
        <NextUIProvider navigate={router.push}>
          <NextThemesProvider defaultTheme="dark">
            <Component {...pageProps} />
          </NextThemesProvider>
        </NextUIProvider>
      </ContextProvider>
  );
}

// export const fonts = {
//   sans: fontSans.style.fontFamily,
//   mono: fontMono.style.fontFamily,
// };
