import create, { State } from "zustand";
import { produce } from "immer";


interface NotificationStore extends State {
  notifications: Array<{
    type: string;
    message: string;
    description?: string;
    txid?: string;
  }>;
  set: (fn: (draft: NotificationStore) => void) => void; // Change the type of fn
}

const useNotificationStore = create<NotificationStore>((set, _get) => ({
  notifications: [],
  set: (fn) => set(produce((draft: NotificationStore) => {
    fn(draft); // Apply the provided function to the draft
  })),
}));

export default useNotificationStore;
